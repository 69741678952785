var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collection-page" },
    [
      _c("HeadNav"),
      _vm.list.length > 0
        ? _c(
            "yd-infinitescroll",
            { ref: "collect", attrs: { callback: _vm.getUserCollectList } },
            [
              _c(
                "div",
                {
                  staticClass: "collection-list",
                  attrs: { slot: "list" },
                  slot: "list",
                },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "collection-item df fdr",
                      on: {
                        click: function ($event) {
                          return _vm.toGoodsDec(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "goods-img",
                        attrs: {
                          src: _vm._f("setHttps")(item.item_info.base_pic),
                          alt: "goods_img",
                        },
                      }),
                      _c("div", { staticClass: "goods-info df fdc jc-sb" }, [
                        _c("div", [
                          _c("p", { staticClass: "title txt-over" }, [
                            item.item_info.source_type
                              ? _c("span", { staticClass: "source-name" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.getSourceTypes(
                                          item.item_info.source_type
                                        )
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(item.item_info.item_name) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                          _c("p", { staticClass: "describe txt-over" }, [
                            _vm._v(_vm._s(item.item_info.item_desc)),
                          ]),
                        ]),
                        item.item_info.display_type === 3 &&
                        item.item_info.installment_plans
                          ? _c("div", { staticClass: "lusse" }, [
                              _c("span", { staticClass: "plans money" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      item.item_info.installment_plans.slice(
                                        -1
                                      )[0].avg_price
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]),
                              _c("span", { staticClass: "plans" }, [
                                _vm._v("元"),
                              ]),
                              _vm._v("/月起\n\t\t\t\t\t"),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "df fdr alc jc-sb" }, [
                          _vm.businessType == 2
                            ? _c(
                                "div",
                                {
                                  staticClass: "price",
                                  class: item.item_info.installment_plans
                                    ? "original-price"
                                    : "",
                                },
                                [
                                  _vm.price_status == 1
                                    ? _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.priceShow(
                                                item.item_info.display_type,
                                                item.item_info.price,
                                                item.item_info
                                                  .max_integral_percentage
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "price",
                                  class: item.item_info.installment_plans
                                    ? "original-price"
                                    : "",
                                },
                                [
                                  _vm.price_status == 1
                                    ? _c("span", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t￥" +
                                            _vm._s(item.item_info.price) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.mall_price_status == 1
                                    ? _c("span", { staticClass: "old" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t￥" +
                                            _vm._s(item.item_info.mall_price) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                          _c(
                            "div",
                            {
                              staticClass: "btn",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.cancelCollect(item, index)
                                },
                              },
                            },
                            [_vm._v("取消收藏")]
                          ),
                        ]),
                        _vm.tripartite_type === "VUE_APP_ZJG" &&
                        item.item_info.display_type !== 1
                          ? _c("div", { staticClass: "zjg-max" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t积分最高抵扣" +
                                  _vm._s(
                                    item.item_info.max_integral_percentage
                                  ) +
                                  "%\n\t\t\t\t\t"
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
                _vm._v("-没有更多了-"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.list.length === 0 && _vm.is_request
        ? _c(
            "div",
            { staticClass: "empty-box" },
            [_c("Empty", { attrs: { tip: "收藏列表空空如也～" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }