<template>
	<div class="collection-page">
		<HeadNav />
		<yd-infinitescroll v-if="list.length > 0" :callback="getUserCollectList" ref="collect">
			<div class="collection-list" slot="list">
				<div
					v-for="(item, index) in list"
					:key="index"
					class="collection-item df fdr"
					@click="toGoodsDec(item)"
				>
					<img class="goods-img" :src="item.item_info.base_pic | setHttps" alt="goods_img" />
					<div class="goods-info df fdc jc-sb">
						<div>
							<p class="title txt-over">
								<span v-if="item.item_info.source_type" class="source-name">
									{{ getSourceTypes(item.item_info.source_type) }}
								</span>
								{{ item.item_info.item_name }}
							</p>
							<p class="describe txt-over">{{ item.item_info.item_desc }}</p>
						</div>
						<div
							v-if="item.item_info.display_type === 3 && item.item_info.installment_plans"
							class="lusse"
						>
							<span class="plans money">
								{{ item.item_info.installment_plans.slice(-1)[0].avg_price }}
							</span>
							<span class="plans">元</span>/月起
						</div>
						<div class="df fdr alc jc-sb">
							<div
								v-if="businessType == 2"
								class="price"
								:class="item.item_info.installment_plans ? 'original-price' : ''"
							>
								<span v-if="price_status == 1">
									{{ priceShow(item.item_info.display_type, item.item_info.price, item.item_info.max_integral_percentage) }}
								</span>
							</div>
							<div
								v-else
								class="price"
								:class="item.item_info.installment_plans ? 'original-price' : ''"
							>
								<span v-if="price_status == 1">
									￥{{ item.item_info.price }}
								</span>
								<span v-if="mall_price_status == 1" class="old">
									￥{{ item.item_info.mall_price }}
								</span>
							</div>
							<div class="btn" @click.stop="cancelCollect(item, index)">取消收藏</div>
						</div>
						
						<div v-if="tripartite_type === 'VUE_APP_ZJG'&&item.item_info.display_type!==1" class="zjg-max">
							积分最高抵扣{{ item.item_info.max_integral_percentage }}%
						</div>
					</div>
				</div>
			</div>
			<!-- 数据全部加载完毕显示 -->
			<span slot="doneTip">-没有更多了-</span>
		</yd-infinitescroll>

		<!-- 空视图 -->
		<div v-if="list.length === 0 && is_request" class="empty-box">
			<Empty tip="收藏列表空空如也～"></Empty>
		</div>
	</div>
</template>

<script>
import Empty from '@/components/empty/Empty';
import HeadNav from '@/components/common/HeadNav';
import { getUserCollectList, addCollect } from '@/services/my';
import { priceShow } from '@/utils/goodsUtil';
import { orderMixin } from '@/mixins/orderMixin';
import { mapState } from 'vuex';

export default {
	name: 'CollectionList',
	components: {
		Empty,
		HeadNav
	},
	mixins: [orderMixin],
	data() {
		return {
			page: 1, // 开始查询的下标
			listRows: 10, // 每次查询的数量
			emptyList: false, // 是否为空
			list: [], //收藏列表
			is_request: 0
		};
	},
	computed: {
		...mapState({
			businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
			price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
			mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
			integralRate: state => state.config.integral_rate, // 积分比例
			tripartite_type: state => state.tripartite_type // 三方商城类型
		})
	},
	methods: {
		// 积分3.0显示
		priceShow,

		async getUserCollectList() {
			try {
				let params = {
					page: this.page,
					listRows: this.listRows
				};
				const res = await getUserCollectList(params);
				this.is_request = 1;
				if (res.code === 0) {
					const data = res.data;
					console.log(data);
					this.list = [...this.list, ...data.list];
					this.total = data.total;
					if (this.list.length === data.total) {
						/* 所有数据加载完毕 */
						this.page++;
						this.$refs.collect.$emit('ydui.infinitescroll.loadedDone');
						return;
					}
					/* 单次请求数据完毕 */
					this.page++;
					this.$refs.collect.$emit('ydui.infinitescroll.finishLoad');
				}
			} catch (e) {}
		},
		async cancelCollect(item, index) {
			try {
				const res = await addCollect(item.item_id);
				if (res.code === 0) {
					this.list.splice(index, 1);
					this.$dialog.toast({
						mes: '取消收藏成功',
						icon: 'success'
					});
				}
			} catch (e) {}
		},
		toGoodsDec(item) {
			this.$router.push({
				name: 'goods-detail',
				query: {
					item_id: item.item_id
				}
			});
		}
	},
	mounted() {
		this.getUserCollectList();
	}
};
</script>

<style lang="less">
@import '../../../common/less/variable.less';
@import '../../../common/less/mixin.less';

.collection-page {
	.collection-list {
		padding: 0.3rem;
	}
	.yd-list-donetip {
		.sc(0.28rem, @color-light-grey);
		margin-bottom: 1.88rem;
	}

	.collection-item {
		height: 2.2rem;
		background: @color-white;
		box-shadow: 0 0.06rem 0.4rem 0 rgba(0, 0, 0, 0.05);
		border-radius: 0.08rem;
		margin-bottom: 0.2rem;

		.goods-img {
			.wh(2.2rem, 2.2rem);
			border-bottom-left-radius: 0.08rem;
			border-top-left-radius: 0.08rem;
		}

		/*商品信息*/

		.goods-info {
			.wh(4.7rem, 100%);
			padding: 0.24rem 0.1rem 0.2rem 0.2rem;

			.title {
				.sc(0.28rem, @color-dark-grey);
				line-height: 0.4rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				.source-name {
					position: relative;
					top: -0.02rem;
					display: inline-block;
					height: 0.3rem;
					line-height: 0.35rem;
					padding: 0 0.15rem;
					border-radius: 0.2rem;
					background-color: var(--main-color);
					color: #ffffff;
					font-size: 0.18rem;
				}
			}
			
			.describe {
				.sc(0.24rem, @color-light-grey);
				line-height: 0.34rem;
				margin-bottom: 0.1rem;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			/*价格*/
			
			.original-price {
				color: #919193 !important;
				font-size: 0.3rem !important;
			}

			.price {
				.sc(0.32rem, @color-dark-grey);
				font-weight: bold;
				margin-top: 0.2rem;
				width: 4rem;

				.old {
					.sc(0.24rem, @color-light-grey);
					text-decoration: line-through;
					margin-left: 0.12rem;
				}
			}
			
			.zjg-max {
				color: var(--main-color);
				font-size: 0.2rem;
			}

			/*收藏按钮*/

			.btn {
				.wh(1.46rem, 0.52rem);
				margin-top: 0.15rem;
				border-radius: 0.26rem;
				border: 0.02rem solid var(--main-color);
				.sc(0.24rem, var(--main-color));
				line-height: 0.52rem;
				text-align: center;
			}
			
			.lusse {
				font-size: 0.2rem;
				
				.plans {
					color: #d5474d;
				}
				.money {
					font-size: 0.4rem;
				}
			}
		}
	}

	/* 列表为空*/

	.empty-box {
		background: @color-white;
		height: 100vh;
	}
}
</style>
